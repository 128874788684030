define('connect-material/components/select/select',['./select.menu', './select.field', './select.search'], function($__0,$__1,$__2) {
  "use strict";
  if (!$__0 || !$__0.__esModule)
    $__0 = {default: $__0};
  if (!$__1 || !$__1.__esModule)
    $__1 = {default: $__1};
  if (!$__2 || !$__2.__esModule)
    $__2 = {default: $__2};
  $__0;
  $__1;
  $__2;
  return {};
});

