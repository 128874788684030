define('connect-material/components/gridlist/gridlist.headers',['../components'], function($__0) {
  "use strict";
  if (!$__0 || !$__0.__esModule)
    $__0 = {default: $__0};
  var materialComponents = $__0.materialComponents;
  materialComponents.directive('materialGridlistHeaders', [function() {
    return {
      restrict: 'E',
      transclude: true,
      template: '<div class="material-gridlist-headers-inner" ng-transclude></div>'
    };
  }]);
  return {};
});

