define('connect-material/components/item/item',['../components', '../icon/icon'], function($__0,$__2) {
  "use strict";
  if (!$__0 || !$__0.__esModule)
    $__0 = {default: $__0};
  if (!$__2 || !$__2.__esModule)
    $__2 = {default: $__2};
  var materialComponents = $__0.materialComponents;
  $__2;
  materialComponents.directive('materialItem', [function() {
    return {
      restrict: 'EA',
      transclude: true,
      template: ['<div class="material-text" ng-transclude></div>'].join('')
    };
  }]);
  return {};
});

