define('connect-material/utils/constant/constant',['../utils'], function($__0) {
  "use strict";
  if (!$__0 || !$__0.__esModule)
    $__0 = {default: $__0};
  var materialUtils = $__0.materialUtils;
  materialUtils.factory('materialConstants', ['$$rAF', '$sniffer', function($$rAF, $sniffer) {
    var webkit = /webkit/i.test($sniffer.vendorPrefix);
    function vendorProperty(name) {
      return webkit ? ('webkit' + name.charAt(0).toUpperCase() + name.substring(1)) : name;
    }
    return {
      KEY_CODE: {
        ENTER: 13,
        ESCAPE: 27,
        SPACE: 32,
        LEFT_ARROW: 37,
        UP_ARROW: 38,
        RIGHT_ARROW: 39,
        DOWN_ARROW: 40
      },
      CSS: {
        TRANSITIONEND: 'transitionend' + (webkit ? ' webkitTransitionEnd' : ''),
        ANIMATIONEND: 'animationend' + (webkit ? ' webkitAnimationEnd' : ''),
        TRANSFORM: vendorProperty('transform'),
        TRANSITION: vendorProperty('transition'),
        TRANSITION_DURATION: vendorProperty('transitionDuration'),
        ANIMATION_PLAY_STATE: vendorProperty('animationPlayState'),
        ANIMATION_DURATION: vendorProperty('animationDuration'),
        ANIMATION_NAME: vendorProperty('animationName'),
        ANIMATION_TIMING: vendorProperty('animationTimingFunction'),
        ANIMATION_DIRECTION: vendorProperty('animationDirection')
      },
      MEDIA: {
        'sm': '(max-width: 600px)',
        'gt-sm': '(min-width: 600px)',
        'md': '(min-width: 600px) and (max-width: 960px)',
        'gt-md': '(min-width: 960px)',
        'lg': '(min-width: 960px) and (max-width: 1200px)',
        'gt-lg': '(min-width: 1200px)'
      }
    };
  }]);
  return {};
});

