define('connect-material/components/button/button',['../components', '../icon/icon'], function($__0,$__2) {
  "use strict";
  if (!$__0 || !$__0.__esModule)
    $__0 = {default: $__0};
  if (!$__2 || !$__2.__esModule)
    $__2 = {default: $__2};
  var materialComponents = $__0.materialComponents;
  $__2;
  materialComponents.directive('materialButton', ['$animate', function() {
    return {
      restrict: 'EA',
      scope: {'label': '@'},
      transclude: true,
      template: '<div ng-if="!label" class="material-text" ng-transclude></div><div class="material-text" ng-if="label">{{label}}</div>'
    };
  }]);
  return {};
});

