define('connect-material/utils/debounce/debounce',['../utils'], function($__0) {
  "use strict";
  if (!$__0 || !$__0.__esModule)
    $__0 = {default: $__0};
  var materialUtils = $__0.materialUtils;
  var now = Date.now || function() {
    return new Date().getTime();
  };
  materialUtils.factory('debounce', ['$timeout', function($timeout) {
    return function(func, wait, immediate) {
      var timeout,
          args,
          context,
          timestamp,
          result;
      var later = function() {
        var last = now() - timestamp;
        if (last < wait && last > 0) {
          timeout = $timeout(later, wait - last);
        } else {
          timeout = null;
          if (!immediate) {
            result = func.apply(context, args);
            if (!timeout) {
              context = args = null;
            }
          }
        }
      };
      return function() {
        context = this;
        args = arguments;
        timestamp = now();
        var callNow = immediate && !timeout;
        if (!timeout) {
          timeout = $timeout(later, wait);
        }
        if (callNow) {
          result = func.apply(context, args);
          context = args = null;
        }
        return result;
      };
    };
  }]);
  return {};
});

