define('connect-material/components/gridlist/gridlist',['../components', 'angular', './gridlist.pagination', './gridlist.headers'], function($__0,$__2,$__4,$__5) {
  "use strict";
  if (!$__0 || !$__0.__esModule)
    $__0 = {default: $__0};
  if (!$__2 || !$__2.__esModule)
    $__2 = {default: $__2};
  if (!$__4 || !$__4.__esModule)
    $__4 = {default: $__4};
  if (!$__5 || !$__5.__esModule)
    $__5 = {default: $__5};
  var materialComponents = $__0.materialComponents;
  var angular = $__2.default;
  $__4;
  $__5;
  materialComponents.directive('materialGridlist', [function() {
    var link = function($scope, element) {
      var headers = element.find('material-gridlist-headers');
      if (headers.length > 0) {
        var cells = headers.find('material-cell');
        var headerTexts = [];
        for (var i = 0,
            len = cells.length; i < len; i++) {
          var cell = angular.element(cells[i]);
          var headerText = cell.text().trim();
          if (!cell.attr('title')) {
            cell.attr('title', headerText);
          }
          headerTexts.push(headerText);
        }
        $scope.headerTexts = headerTexts;
      }
    };
    return {link: link};
  }]);
  materialComponents.directive('materialRow', [function() {
    var link = function($scope, element) {
      if ($scope.headerTexts && $scope.headerTexts.length > 0) {
        var checkbox = element.find('material-checkbox');
        var cells = element.find('material-cell');
        if (checkbox.length > 0) {
          element.addClass('has-checkbox');
        }
        for (var i = 0,
            len = cells.length; i < len; i++) {
          var cell = angular.element(cells[i]);
          var headerText = $scope.headerTexts[i];
          if (headerText && headerText.length > 0) {
            cell.attr('data-mobile-label', headerText + ': ');
          }
        }
      }
    };
    return {link: link};
  }]);
  return {};
});

